<template>
  <v-row class="step-two">
    <register-form-step-two
      :emails="emailsList"
      @send="sendRegister"
    />
    <help-button
      @help="goToHelp"
    />
  </v-row>
</template>

<script>
import RegisterFormStepTwo from '@/components/forms/register/StepTwo.vue';
import HelpButton from '@/components/forms/register/HelpButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StepTwo',
  components: {
    RegisterFormStepTwo,
    HelpButton,
  },
  data() {
    return {
      email: '',
      emails: [],
    };
  },
  computed: {
    ...mapGetters([
      'getEmails',
    ]),
    emailsList() {
      return this.emails;
    },
  },
  mounted() {
    this.changeToolbarText('Cadastro');
    this.emails = this.getEmails.emails;
  },
  methods: {
    ...mapActions([
      'changeToolbarText',
      'register',
    ]),
    sendRegister(userData) {
      this.register(userData);
    },
    goToHelp() {
      this.$router.push({
        name: 'help',
      });
    },
  },
};
</script>
<style lang="scss">
.step-two {
  padding: 16px 16px 100px;
  font-size: $font-size-extra-small;
  @include font-monteserrat-regular;
  color: $text-color-secondary;

  label {
    @include font-monteserrat-bold;
    color: $text-color-secondary;
    font-size: $font-size-extra-small;
  }
  .edudash-text-field {
    label {
      @include font-monteserrat-regular;
      color: $text-color-secondary;
    }
  }
  .edudash-button {
    width: 164px;
  }
}
</style>
