<template>
  <v-row class="fill-height register-step-one">
    <v-col cols="12">
      <register-form-step-one @next="goToStepTwo" />
    </v-col>
    <v-col cols="12">
      <help-button
        @help="goToHelp"
      />
    </v-col>
  </v-row>
</template>
<script>
import RegisterFormStepOne from '@/components/forms/register/StepOne.vue';
import HelpButton from '@/components/forms/register/HelpButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StepOne',
  components: {
    RegisterFormStepOne,
    HelpButton,
  },
  computed: {
    ...mapGetters([
      'getEmails',
    ]),
  },
  watch: {
    getEmails(value) {
      if (value) {
        this.$router.push({
          name: 'registerStepTwo',
        });
      }
    },
  },
  mounted() {
    this.changeToolbarText('Cadastro');
  },
  methods: {
    ...mapActions([
      'changeToolbarText',
      'findTeacher',
    ]),
    goToStepTwo(userData) {
      this.findTeacher(userData);
    },
    goToHelp() {
      this.$router.push({
        name: 'help',
      });
    },
  },
};
</script>

<style lang="scss">
  .register-step-one {
    height: 100%;
    padding: 0 16px;
  }
</style>
