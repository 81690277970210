<template>
  <div class="register">
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'Register',
};
</script>
