<template>
  <v-col cols="12">
    {{ $t('components.labels.select_email') }}
    <v-radio-group
      v-model="email"
      column
    >
      <v-radio
        v-for="(emailListed, index) in emails"
        :key="index"
        :label="emailListed.hidden_email"
        :value="emailListed.email"
      />
    </v-radio-group>
    <validation-observer>
      <edudash-input
        v-model="password"
        prepend-icon="https"
        :name="$t('views.new_password.password')"
        :label="$t('components.labels.password')"
        rules="required|password:@confirmation"
        type="password"
        class="mb-4"
      />
      <edudash-input
        :ref="$t('views.new_password.password_confirmation')"
        v-model="passwordConfirmation"
        vid="confirmation"
        prepend-icon="https"
        :label="$t('components.labels.confirm_password')"
        type="password"
        rules="required"
        class="mb-4"
      />
    </validation-observer>
    <edudash-button
      class="edudash-button mt-4"
      :full-width="false"
      @click="sendRegister"
    />
  </v-col>
</template>
<script>
import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashInput from '@/components/forms/EdudashInput.vue';

export default {
  name: 'RegisterFormStepTwo',
  components: {
    EdudashButton,
    EdudashInput,
  },
  props: {
    emails: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      email: '',
    };
  },
  methods: {
    sendRegister() {
      const userData = {
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };
      this.$emit('send', userData);
    },
  },
};
</script>
