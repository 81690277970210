<template>
  <v-row class="step-one">
    <v-col
      class="pa-0 mt-5"
      cols="12"
    >
      <edudash-input
        v-model="cpf"
        rules="required|cpf"
        prepend-icon="description"
        :name="$t('components.labels.cpf_secondary')"
        :label="$t('components.labels.cpf')"
        :mask="cpfMask"
      />
      <edudash-input
        v-model="birthday"
        :mask="birthdayMask"
        name="data de nascimento"
        prepend-icon="date_range"
        :label="$t('components.labels.birthday')"
      />
      <edudash-button
        class="edudash-button mt-4"
        :full-width="false"
        :text="$t('components.register_form.next')"
        @click="goToNextStep"
      />
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashInput from '@/components/forms/EdudashInput.vue';

export default {
  name: 'RegisterFormStepOne',
  components: {
    EdudashButton,
    EdudashInput,
  },
  data() {
    return {
      cpf: '',
      birthday: '',
    };
  },
  computed: {
    cpfMask() {
      return '###.###.###-##';
    },
    birthdayMask() {
      return '##/##/####';
    },
  },
  methods: {
    goToNextStep() {
      const userData = {
        cpf: this.unmask(this.cpf),
        birthday: this.birthday,
      };
      this.$emit('next', userData);
    },
  },
};
</script>
<style lang="scss">
  .step-one {
    @include font-monteserrat-semi-bold;
    padding: 16px 16px 80px;
    font-size: $font-size-extra-small;
    height: 100%;

    .v-text-field {
      padding: 0;

      .v-label {
        font-size: $font-size-extra-small;
        color: $text-color-secondary;
      }
    }

    .v-icon.v-icon {
      color: $text-color-secondary;
    }

    input {
      font-size: $font-size-extra-small;
      color: $text-color-secondary;
    }

    .edudash-button {
      width: 164px;
    }
  }
</style>
