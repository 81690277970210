<template>
  <div class="help">
    <hr>
    <div
      class="help-body text-center"
      align-self="end"
      cols="12"
      @click="emitHelp"
    >
      <span class="help-title">
        {{ $t('components.labels.help_title') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpButton',
  methods: {
    emitHelp() {
      this.$emit('help');
    },
  },
};
</script>

<style lang="scss">
.help {
  color: $secondary-button-color;
  font-size: $font-size-extra-small;
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;

  hr {
    border: 1px solid $border-color;
  }

  .help-body {
    margin: 32px 0;
    .help-title {
      @include font-open-sans-bold;
      margin-top: 18px;
    }
  }
}
</style>
